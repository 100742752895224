/**
 * @generated SignedSource<<de0d96dfb03733906db76130f6134db0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SelectGroupMembersListItemFragment$data = {
  readonly id: string;
  readonly member: {
    readonly fullName: string;
    readonly " $fragmentSpreads": FragmentRefs<"ProfileAvatarFragment">;
  };
  readonly " $fragmentType": "SelectGroupMembersListItemFragment";
};
export type SelectGroupMembersListItemFragment$key = {
  readonly " $data"?: SelectGroupMembersListItemFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"SelectGroupMembersListItemFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SelectGroupMembersListItemFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "member",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "fullName",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ProfileAvatarFragment"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ProductMembership",
  "abstractKey": null
};

(node as any).hash = "4d653606616b7231de6680b3fefcfd67";

export default node;
