/**
 * @generated SignedSource<<03282d9ffa3255fcb5e5a95eecb11b4c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CurriculumProfileListModalContentQuery$variables = {
  id: string;
  contentUsageId: string;
};
export type CurriculumProfileListModalContentQuery$data = {
  readonly product: {
    readonly id?: string;
    readonly attendees?: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id: string;
          readonly " $fragmentSpreads": FragmentRefs<"CurriculumProfileListModalListItemFragment">;
        };
      }>;
    };
  } | null;
  readonly contentUsage: {
    readonly completedProductMemberships?: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id: string;
          readonly " $fragmentSpreads": FragmentRefs<"CurriculumProfileListModalListItemFragment">;
        };
      }>;
    };
  } | null;
};
export type CurriculumProfileListModalContentQuery = {
  variables: CurriculumProfileListModalContentQuery$variables;
  response: CurriculumProfileListModalContentQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "contentUsageId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v2 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "ProductMembershipNodeEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ProductMembership",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "CurriculumProfileListModalListItemFragment"
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
],
v5 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "contentUsageId"
  }
],
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v7 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "ProductMembershipNodeEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ProductMembership",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "member",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "firstName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "fullName",
                "storageKey": null
              },
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "avatar",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isTest",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "CurriculumProfileListModalContentQuery",
    "selections": [
      {
        "alias": "product",
        "args": (v2/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "ProductMembershipNodeConnection",
                "kind": "LinkedField",
                "name": "attendees",
                "plural": false,
                "selections": (v4/*: any*/),
                "storageKey": null
              }
            ],
            "type": "Product",
            "abstractKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": "contentUsage",
        "args": (v5/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ProductMembershipNodeConnection",
                "kind": "LinkedField",
                "name": "completedProductMemberships",
                "plural": false,
                "selections": (v4/*: any*/),
                "storageKey": null
              }
            ],
            "type": "ContentUsage",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "CurriculumProfileListModalContentQuery",
    "selections": [
      {
        "alias": "product",
        "args": (v2/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v6/*: any*/),
          (v3/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ProductMembershipNodeConnection",
                "kind": "LinkedField",
                "name": "attendees",
                "plural": false,
                "selections": (v7/*: any*/),
                "storageKey": null
              }
            ],
            "type": "Product",
            "abstractKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": "contentUsage",
        "args": (v5/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v6/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ProductMembershipNodeConnection",
                "kind": "LinkedField",
                "name": "completedProductMemberships",
                "plural": false,
                "selections": (v7/*: any*/),
                "storageKey": null
              }
            ],
            "type": "ContentUsage",
            "abstractKey": null
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "1e76ebd390d36fb8caa2694c45829b9f",
    "id": null,
    "metadata": {},
    "name": "CurriculumProfileListModalContentQuery",
    "operationKind": "query",
    "text": "query CurriculumProfileListModalContentQuery(\n  $id: ID!\n  $contentUsageId: ID!\n) {\n  product: node(id: $id) {\n    __typename\n    ... on Product {\n      id\n      attendees {\n        edges {\n          node {\n            id\n            ...CurriculumProfileListModalListItemFragment\n          }\n        }\n      }\n    }\n    id\n  }\n  contentUsage: node(id: $contentUsageId) {\n    __typename\n    ... on ContentUsage {\n      completedProductMemberships {\n        edges {\n          node {\n            id\n            ...CurriculumProfileListModalListItemFragment\n          }\n        }\n      }\n    }\n    id\n  }\n}\n\nfragment CurriculumProfileListModalListItemFragment on ProductMembership {\n  id\n  member {\n    firstName\n    fullName\n    ...ProfileAvatarFragment\n    id\n  }\n}\n\nfragment ProfileAvatarFragment on User {\n  id\n  fullName\n  avatar\n  isTest\n}\n"
  }
};
})();

(node as any).hash = "18bb4b50c281215d0ef3c4d0b5a77a5c";

export default node;
