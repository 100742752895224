/**
 * @generated SignedSource<<425858b9463f555129de7fe6bf9125de>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type MemberGroupKind = "default" | "custom" | "role" | "test_user" | "%future added value";
export type ProductType = "course" | "membership_plan" | "community_event" | "pathway" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type MemberGroupEventTagListFragment$data = {
  readonly id: string;
  readonly product: {
    readonly type: ProductType;
  };
  readonly memberGroups: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly kind: MemberGroupKind;
        readonly parentMemberGroupId: string | null;
        readonly name: string;
        readonly product: {
          readonly name: string;
        } | null;
        readonly " $fragmentSpreads": FragmentRefs<"MemberGroupTagFragment">;
      };
    }>;
  };
  readonly " $fragmentType": "MemberGroupEventTagListFragment";
};
export type MemberGroupEventTagListFragment$key = {
  readonly " $data"?: MemberGroupEventTagListFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"MemberGroupEventTagListFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "MemberGroupEventTagListFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Product",
      "kind": "LinkedField",
      "name": "product",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "type",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "MemberGroupNodeConnection",
      "kind": "LinkedField",
      "name": "memberGroups",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "MemberGroupNodeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "MemberGroup",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "kind",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "parentMemberGroupId",
                  "storageKey": null
                },
                (v1/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Product",
                  "kind": "LinkedField",
                  "name": "product",
                  "plural": false,
                  "selections": [
                    (v1/*: any*/)
                  ],
                  "storageKey": null
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "MemberGroupTagFragment"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Event",
  "abstractKey": null
};
})();

(node as any).hash = "783ebe70237ec29b7f6a22261827e413";

export default node;
