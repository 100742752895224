/**
 * @generated SignedSource<<3e3be2a32e9dae71205046a4c7a11176>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type MemberGroupKind = "default" | "custom" | "role" | "test_user" | "%future added value";
export type MemberGroupVisibility = "everyone" | "admin_only" | "%future added value";
export type ProductType = "course" | "membership_plan" | "community_event" | "pathway" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type MemberGroupsMultiSelect_ProductFragment$data = {
  readonly id: string;
  readonly memberGroups: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly name: string;
        readonly color: string;
        readonly viewerIsMember: boolean;
        readonly kind: MemberGroupKind;
        readonly isSystem: boolean;
        readonly parentMemberGroupId: string | null;
        readonly visibility: MemberGroupVisibility;
        readonly product: {
          readonly id: string;
          readonly name: string;
          readonly type: ProductType;
        } | null;
        readonly childrenGroups: {
          readonly totalCount: number;
          readonly edges: ReadonlyArray<{
            readonly node: {
              readonly id: string;
              readonly name: string;
              readonly color: string;
              readonly viewerIsMember: boolean;
              readonly kind: MemberGroupKind;
              readonly parentMemberGroupId: string | null;
              readonly visibility: MemberGroupVisibility;
              readonly product: {
                readonly id: string;
                readonly name: string;
              } | null;
              readonly " $fragmentSpreads": FragmentRefs<"MemberGroupTagFragment">;
            };
          }>;
        };
        readonly " $fragmentSpreads": FragmentRefs<"MemberGroupTagFragment">;
      };
    }>;
  };
  readonly " $fragmentType": "MemberGroupsMultiSelect_ProductFragment";
};
export type MemberGroupsMultiSelect_ProductFragment$key = {
  readonly " $data"?: MemberGroupsMultiSelect_ProductFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"MemberGroupsMultiSelect_ProductFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "color",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "viewerIsMember",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "kind",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "parentMemberGroupId",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "visibility",
  "storageKey": null
},
v7 = {
  "args": null,
  "kind": "FragmentSpread",
  "name": "MemberGroupTagFragment"
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "MemberGroupsMultiSelect_ProductFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "kind",
          "value": "custom"
        }
      ],
      "concreteType": "MemberGroupNodeConnection",
      "kind": "LinkedField",
      "name": "memberGroups",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "MemberGroupNodeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "MemberGroup",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v1/*: any*/),
                (v2/*: any*/),
                (v3/*: any*/),
                (v4/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "isSystem",
                  "storageKey": null
                },
                (v5/*: any*/),
                (v6/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Product",
                  "kind": "LinkedField",
                  "name": "product",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    (v1/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "type",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                (v7/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "MemberGroupNodeConnection",
                  "kind": "LinkedField",
                  "name": "childrenGroups",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "totalCount",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "MemberGroupNodeEdge",
                      "kind": "LinkedField",
                      "name": "edges",
                      "plural": true,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "MemberGroup",
                          "kind": "LinkedField",
                          "name": "node",
                          "plural": false,
                          "selections": [
                            (v0/*: any*/),
                            (v1/*: any*/),
                            (v2/*: any*/),
                            (v3/*: any*/),
                            (v4/*: any*/),
                            (v5/*: any*/),
                            (v6/*: any*/),
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "Product",
                              "kind": "LinkedField",
                              "name": "product",
                              "plural": false,
                              "selections": [
                                (v0/*: any*/),
                                (v1/*: any*/)
                              ],
                              "storageKey": null
                            },
                            (v7/*: any*/)
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "memberGroups(kind:\"custom\")"
    }
  ],
  "type": "Product",
  "abstractKey": null
};
})();

(node as any).hash = "6358b1b9f081e11dcd5ca81ba8ca0629";

export default node;
