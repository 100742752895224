/**
 * @generated SignedSource<<4b516bfd193ef2a4601a769882dba6a7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CourseGroupsListItemAvatarStackFragment$data = {
  readonly id: string;
  readonly name: string;
  readonly sampleMemberships: {
    readonly totalCount: number;
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly user: {
          readonly id: string;
          readonly firstName: string | null;
          readonly lastName: string | null;
          readonly " $fragmentSpreads": FragmentRefs<"ProfileAvatarFragment" | "ProfileListModalListItemFragment">;
        };
      };
    }>;
  };
  readonly " $fragmentType": "CourseGroupsListItemAvatarStackFragment";
};
export type CourseGroupsListItemAvatarStackFragment$key = {
  readonly " $data"?: CourseGroupsListItemAvatarStackFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"CourseGroupsListItemAvatarStackFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CourseGroupsListItemAvatarStackFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": "sampleMemberships",
      "args": [
        {
          "kind": "Literal",
          "name": "first",
          "value": 3
        }
      ],
      "concreteType": "MemberGroupMembershipNodeConnection",
      "kind": "LinkedField",
      "name": "memberGroupMemberships",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalCount",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "MemberGroupMembershipNodeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "MemberGroupMembership",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "User",
                  "kind": "LinkedField",
                  "name": "user",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "firstName",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "lastName",
                      "storageKey": null
                    },
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "ProfileAvatarFragment"
                    },
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "ProfileListModalListItemFragment"
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "memberGroupMemberships(first:3)"
    }
  ],
  "type": "MemberGroup",
  "abstractKey": null
};
})();

(node as any).hash = "fdd847068ec5bc0268a190bf9c1e2d79";

export default node;
