/**
 * @generated SignedSource<<d71e268fc63eb35a1a4e75eea80d06da>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AddMembersToGroupsModalQuery$variables = {
  productId: string;
  membershipIds?: ReadonlyArray<string> | null;
};
export type AddMembersToGroupsModalQuery$data = {
  readonly node: {
    readonly id?: string;
    readonly defaultMemberGroup?: {
      readonly " $fragmentSpreads": FragmentRefs<"CreateMemberGroupButtonFragment">;
    };
    readonly " $fragmentSpreads": FragmentRefs<"SelectGroupMembersListFragment" | "MemberGroupsMultiSelect_ProductFragment">;
  } | null;
};
export type AddMembersToGroupsModalQuery = {
  variables: AddMembersToGroupsModalQuery$variables;
  response: AddMembersToGroupsModalQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "membershipIds"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "productId"
},
v2 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "productId"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "color",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "viewerIsMember",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "kind",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isSystem",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "parentMemberGroupId",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "visibility",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "role",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "AddMembersToGroupsModalQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "MemberGroup",
                "kind": "LinkedField",
                "name": "defaultMemberGroup",
                "plural": false,
                "selections": [
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "CreateMemberGroupButtonFragment"
                  }
                ],
                "storageKey": null
              },
              {
                "args": [
                  {
                    "kind": "Variable",
                    "name": "membershipIds",
                    "variableName": "membershipIds"
                  }
                ],
                "kind": "FragmentSpread",
                "name": "SelectGroupMembersListFragment"
              },
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "MemberGroupsMultiSelect_ProductFragment"
              }
            ],
            "type": "Product",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "AddMembersToGroupsModalQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v3/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "MemberGroup",
                "kind": "LinkedField",
                "name": "defaultMemberGroup",
                "plural": false,
                "selections": [
                  (v3/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Variable",
                    "name": "ids",
                    "variableName": "membershipIds"
                  }
                ],
                "concreteType": "ProductMembershipNodeConnection",
                "kind": "LinkedField",
                "name": "productMemberships",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ProductMembershipNodeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ProductMembership",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "User",
                            "kind": "LinkedField",
                            "name": "member",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "fullName",
                                "storageKey": null
                              },
                              (v3/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "avatar",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "isTest",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Literal",
                    "name": "kind",
                    "value": "custom"
                  }
                ],
                "concreteType": "MemberGroupNodeConnection",
                "kind": "LinkedField",
                "name": "memberGroups",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "MemberGroupNodeEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "MemberGroup",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          (v4/*: any*/),
                          (v5/*: any*/),
                          (v6/*: any*/),
                          (v7/*: any*/),
                          (v8/*: any*/),
                          (v9/*: any*/),
                          (v10/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Product",
                            "kind": "LinkedField",
                            "name": "product",
                            "plural": false,
                            "selections": [
                              (v3/*: any*/),
                              (v4/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "type",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          (v11/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "MemberGroupNodeConnection",
                            "kind": "LinkedField",
                            "name": "childrenGroups",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "totalCount",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "MemberGroupNodeEdge",
                                "kind": "LinkedField",
                                "name": "edges",
                                "plural": true,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "MemberGroup",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                      (v3/*: any*/),
                                      (v4/*: any*/),
                                      (v5/*: any*/),
                                      (v6/*: any*/),
                                      (v7/*: any*/),
                                      (v9/*: any*/),
                                      (v10/*: any*/),
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "Product",
                                        "kind": "LinkedField",
                                        "name": "product",
                                        "plural": false,
                                        "selections": [
                                          (v3/*: any*/),
                                          (v4/*: any*/)
                                        ],
                                        "storageKey": null
                                      },
                                      (v11/*: any*/),
                                      (v8/*: any*/)
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "memberGroups(kind:\"custom\")"
              }
            ],
            "type": "Product",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "fbbf72f1f8f6c677b8b2bc5da195a8a5",
    "id": null,
    "metadata": {},
    "name": "AddMembersToGroupsModalQuery",
    "operationKind": "query",
    "text": "query AddMembersToGroupsModalQuery(\n  $productId: ID!\n  $membershipIds: [ID!]\n) {\n  node(id: $productId) {\n    __typename\n    ... on Product {\n      id\n      defaultMemberGroup {\n        ...CreateMemberGroupButtonFragment\n        id\n      }\n      ...SelectGroupMembersListFragment_QqoIA\n      ...MemberGroupsMultiSelect_ProductFragment\n    }\n    id\n  }\n}\n\nfragment CreateMemberGroupButtonFragment on MemberGroup {\n  ...CreateMemberGroupModalFragment\n}\n\nfragment CreateMemberGroupModalFragment on MemberGroup {\n  id\n}\n\nfragment MemberGroupTagFragment on MemberGroup {\n  id\n  name\n  kind\n  color\n  role\n  isSystem\n  product {\n    id\n    name\n  }\n}\n\nfragment MemberGroupsMultiSelect_ProductFragment on Product {\n  id\n  memberGroups(kind: custom) {\n    edges {\n      node {\n        id\n        name\n        color\n        viewerIsMember\n        kind\n        isSystem\n        parentMemberGroupId\n        visibility\n        product {\n          id\n          name\n          type\n        }\n        ...MemberGroupTagFragment\n        childrenGroups {\n          totalCount\n          edges {\n            node {\n              id\n              name\n              color\n              viewerIsMember\n              kind\n              parentMemberGroupId\n              visibility\n              product {\n                id\n                name\n              }\n              ...MemberGroupTagFragment\n            }\n          }\n        }\n      }\n    }\n  }\n}\n\nfragment ProfileAvatarFragment on User {\n  id\n  fullName\n  avatar\n  isTest\n}\n\nfragment SelectGroupMembersListFragment_QqoIA on Product {\n  productMemberships(ids: $membershipIds) {\n    edges {\n      node {\n        id\n        ...SelectGroupMembersListItemFragment\n      }\n    }\n  }\n}\n\nfragment SelectGroupMembersListItemFragment on ProductMembership {\n  id\n  member {\n    fullName\n    ...ProfileAvatarFragment\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "fea9a0588305aa777080c9fe377b561a";

export default node;
